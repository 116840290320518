import React, { useState, useEffect } from 'react';
import { CookieBanner as Cookie } from '@keepist/react-gdpr-cookie-banner';
import useCookieConsent from '../../hooks/useCookieConsent';

export const CookieBanner = () => {
  const [marketing, setMarketing] = useState(false);
  const [statistics, setStatistics] = useState(false);
  const { acceptMarketing, acceptStatistics } = useCookieConsent();

  useEffect(() => {
    const statisticsCheckbox = document.querySelector(
      '#check-statistics-cookies',
    );
    const marketingCheckbox = document.querySelector(
      '#check-marketing-cookies',
    );

    const handleCheckboxChange = (event) => {
      setStatistics(event.target.checked);
    };

    const handleMarketingCheckboxChange = (event) => {
      setMarketing(event.target.checked);
    };

    if (statisticsCheckbox) {
      statisticsCheckbox.addEventListener('change', handleCheckboxChange);
    }

    if (marketingCheckbox) {
      marketingCheckbox.addEventListener(
        'change',
        handleMarketingCheckboxChange,
      );
    }

    return () => {
      if (statisticsCheckbox) {
        statisticsCheckbox.removeEventListener('change', handleCheckboxChange);
      }
      if (marketingCheckbox) {
        marketingCheckbox.removeEventListener(
          'change',
          handleMarketingCheckboxChange,
        );
      }
    };
  }, []);

  const updateValues = (acceptAll = false) => {
    if (acceptAll) {
      acceptMarketing();
      acceptStatistics();
    } else {
      if (marketing === true) acceptMarketing();
      if (statistics === true) acceptStatistics();
    }
  };

  return (
    <>
      <Cookie
        showPreferencesOption={false}
        showAcceptSelectionButton={true}
        policyLink="/privacy-policy"
        message="We use cookies to enhance your experience and analyze our traffic"
        onAcceptPreferences={() => updateValues(true)}
        onAccept={() => updateValues(false)}
      />
    </>
  );
};
