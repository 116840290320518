import { styled } from '@mui/material';
import theme from 'theme/theme';

const BaseCollapseButtonLeftRoot = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  right: '-31px',
  transform: 'translateY(-50%)',
  borderBottom: `25px solid transparent`,
  borderLeft: `30px solid ${theme.white}`,
  borderTop: `25px solid transparent`,
  height: 100,
  width: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  '&:hover': {
    opacity: 0.7,
  },

  svg: {
    marginRight: '30px',
  },
}));

const BaseCollapseButtonRightRoot = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '-30px',
  transform: 'translateY(-50%)',
  borderBottom: `25px solid transparent`,
  borderRight: `30px solid ${theme.white}`,
  borderTop: `25px solid transparent`,
  height: 100,
  width: 0,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.7,
  },

  svg: {
    marginLeft: '5px',
  },
}));

export { BaseCollapseButtonRightRoot, BaseCollapseButtonLeftRoot };
