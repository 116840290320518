import { styled } from '@mui/material';

import color from 'theme/theme';

const SidebarRoot = styled('div')(({ visible, theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  width: visible ? 320 : 100,
  height: '100vh',
  backgroundColor: color.white,
  color: color.headlines,
  paddingTop: 96,
  borderRight: '1px solid #D6E0EA',
  [theme.breakpoints.down('md')]: {
    width: visible ? 240 : 60,
  },
}));

const SidebarContent = styled('div')(({theme}) => ({
  padding: '48px 0 72px',
  borderBottom: '1px solid #D6E0EA',
  [theme.breakpoints.down('md')]: {
    // padding: '24px 0 72px',
  },
}));

const SidebarItem = styled('div')(({ active, theme }) => ({
  cursor: 'pointer',
  padding: '24px 40px',
  color: active ? color.white : color.primary,
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  fontFamily: 'Lato',
  fontSize: '16px',
  lineHeight: 1.2,
  backgroundColor: active ? color.brand : color.white,
  transition: 'all .3s',
  [theme.breakpoints.down('md')]: {
    padding: '24px 20px',
  },
  '&:hover': {
    backgroundColor: color.brand,
    color: color.white,
  },
}));

export { SidebarContent, SidebarItem };
export default SidebarRoot;
