import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const REDUX_CHAT = 'chat';

const API_CHAT_HISTORY = '/chat/message/all';
const REDUX_CHAT_HISTORY = 'chat/history';

const API_CHAT_SEND = '/chat/message/send';
const REDUX_CHAT_SEND = 'chat/send';

export const getChatHistoryAction = createAsyncThunk(
  REDUX_CHAT_HISTORY,
  async (parameters, { rejectWithValue }) => {
    try {
      const response = await api.get(API_CHAT_HISTORY, {
        params: { ...parameters },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const sendMessageAction = createAsyncThunk(
  REDUX_CHAT_SEND,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_CHAT_SEND, {
        ...payload,
      });
      return { ...response.data.data, success: response.data.success };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export default REDUX_CHAT;
