import React from 'react';

import { BaseButton, BaseCard, H3, Text } from 'UI';
import PriceCardRoot, {
  Line,
  PriceItem,
  PriceList,
  PriceText,
} from './PriceCard.styled';

import thumbUp from 'assets/images/ThumbUp.png';
import theme from 'theme/theme';

const PriceCard = ({ main, p, title, price, list, onClick }) => {
  return (
    <BaseCard
      p={p}
      sx={{
        boxShadow: main ? theme.hoverShadow : 'unset',
        width: {
          xs: '350px',
          sm: '350px',
          lg: '430px',
        },
      }}
      // width="430px"
      position="relative"
    >
      {main && <Line />}
      <PriceCardRoot>
        <H3>{title}</H3>

        {/* <PriceText>
          <p>${price}</p>
          <span>/</span>
          <span>month</span>
        </PriceText> */}

        <PriceList>
          {list?.map((item, index) => (
            <PriceItem key={item + index}>
              <img src={thumbUp} alt="icon" />
              <Text style={{ color: theme.primary }}>{item}</Text>
            </PriceItem>
          ))}
        </PriceList>

        <BaseButton
          onClick={onClick}
          label="Select"
          length="100%"
          sx={{ padding: '14px 10px', borderRadius: '24px', marginTop: 'auto' }}
        />
      </PriceCardRoot>
    </BaseCard>
  );
};

export default PriceCard;
