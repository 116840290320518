export const HOMEPAGE_ROUTE = '/';
export const ABOUT_US_ROUTE = '/about-us';
export const OUR_PRODUCT_ROUTE = '/our-product';
export const BADGES_ROUTE = '/badges';
export const BADGE_DIRECTORY_ROUTE = '/badge-directory';
export const LOCKED_ROUTE = '/locked';

export const PAYMENT_ROUTE = '/payment';
export const PAYMENT_SUCCESS = '/payment/success';
export const PAYMENT_CANCEL = '/payment/cancel';
export const CHANGE_PLAN = '/change-plan';

export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const STANDARDS_ROUTE = '/standards';

export const LOGIN_ROUTE = '/login';
export const SIGN_ROUTE = '/register';
export const RECOVERY_PASSWORD_ROUTE = '/recovery-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';

export const DASHBOARD_ROUTE = '/dashboard';
export const SUBSCRIPTION_ROUTE = '/dashboard/subscription';
export const DASHBOARD_SUPPORT_ROUTE = '/dashboard/support';
export const DASHBOARD_DIRECTORY_ROUTE = '/dashboard/badge-directory';
