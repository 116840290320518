import React, { Suspense } from 'react';

import AppProvider from 'providers/AppProvider';
import { LoaderScreen } from 'components';
import Version from 'UI/Version/Version';
import Router from 'routes/router';
import { CookieBanner } from 'components/CookieConsent/CookieBanner';

const App = () => {
  return (
    <AppProvider>
      <CookieBanner />
      <Suspense fallback={<LoaderScreen />}>
        <Router />
        <Version />
      </Suspense>
    </AppProvider>
  );
};

export default App;
