import { styled } from '@mui/material';

const DashboardContent = styled('div')(({ sidebar, theme }) => ({
  padding: sidebar ? '148px 0px 48px 388px' : '148px 0px 48px 168px',
  [theme.breakpoints.down('md')]: {
    padding: sidebar ? '148px 0px 48px 388px' : '118px 0px 48px 75px',
  },

  h2: {
    marginBottom: 36,
    [theme.breakpoints.down('md')]: {
      // display: 'none'
      marginBottom: 10,
    },
  },
}));

export default DashboardContent;
