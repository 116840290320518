import { Radio, styled } from '@mui/material';
import Color from 'theme/theme';

const GroupOptionsRoot = styled('div')(() => ({
  flex: '1 1 0',
  flexDirection: 'column',
  display: 'flex',
  gap: 48,
}));

const OptionRoot = styled('div')(({ active, theme }) => ({
  backgroundColor: Color.white,
  padding: 16,
  borderRadius: 16,
  border: active ? `1px solid ${Color.brand}` : `1px solid ${Color.border}`,
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  [theme.breakpoints.down('md')]: {
    width: '95%',
    gap: 5,
    padding: 13,
  },
  maxWidth: 420,
}));

const OptionRadio = styled(Radio)(() => ({
  padding: 0,
  color: Color.brand,
  '&.Mui-checked': {
    color: Color.brand,
  },
}));

const OptionGroup = styled('div')(() => ({
  flexDirection: 'column',
  display: 'flex',
  gap: 24,
}));

export { OptionRoot, OptionRadio, OptionGroup };
export default GroupOptionsRoot;
