import { useLocation, useNavigate } from 'react-router';
import React, { Fragment, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import { Logo } from 'layout';
import RootNavigation, {
  ButtonGroup,
  MobileMenuRoot,
  MobileSidebarInner,
  NavigationInner,
  NavigationMenu,
  RootMobileSidebar,
} from './Navigation.styled';
import {
  BaseButton,
  BaseContainer,
  BaseNavlink,
  buttonType,
  containerSize,
  navlinkMode,
} from 'UI';

import { DASHBOARD_ROUTE, LOGIN_ROUTE, SIGN_ROUTE } from 'routes/path';
import DICTIONARY from 'constants/Dictionary';
import menuList from '../menuList';

import usePermissions from 'hooks/usePermissions';

const Navigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeMobile, setActiveMobile] = useState(false);

  const { token } = usePermissions();

  const handleMobileSidebar = () => {
    setActiveMobile((prevState) => !prevState);
  };

  const handleLoginClick = () => {
    handleMobileSidebar();
    navigate(LOGIN_ROUTE);
  };

  const handleLogoutClick = () => {
    localStorage.removeItem(DICTIONARY.ACCESS_TOKEN);
    navigate('/');
    handleMobileSidebar();
  };

  const handleSignClick = () => {
    handleMobileSidebar();
    navigate(SIGN_ROUTE);
  };

  const to = (path) => {
    navigate(path);
    handleMobileSidebar();
  };

  return (
    <RootNavigation>
      <BaseContainer size={containerSize.lg}>
        <NavigationInner>
          <Logo />

          <NavigationMenu>
            {menuList.map((item) => (
              <BaseNavlink
                active={item?.path === pathname}
                href={item.path}
                label={item.label}
                mode={navlinkMode.primary}
                key={item.label}
              />
            ))}

            {token && (
              <BaseNavlink
                href={DASHBOARD_ROUTE}
                label="Dashboard"
                mode={navlinkMode.primary}
              />
            )}

            <ButtonGroup>
              {token && (
                <BaseButton
                  type={buttonType.accent}
                  label="Log out"
                  onClick={handleLogoutClick}
                />
              )}
              {!token && (
                <Fragment>
                  <BaseButton
                    type={buttonType.accent}
                    label="Log in"
                    onClick={handleLoginClick}
                  />
                  <BaseButton
                    type={buttonType.primary}
                    label="Sign Up"
                    onClick={handleSignClick}
                  />
                </Fragment>
              )}
            </ButtonGroup>
          </NavigationMenu>

          <MobileMenuRoot onClick={handleMobileSidebar}>
            {activeMobile ? <CloseIcon /> : <MenuIcon />}
          </MobileMenuRoot>
        </NavigationInner>
      </BaseContainer>

      <RootMobileSidebar active={activeMobile ? 1 : 0}>
        <MobileSidebarInner>
          {menuList.map((item) => (
            <BaseNavlink
              onClick={() => to(item.path)}
              active={item?.path === pathname}
              href={item.path}
              label={item.label}
              mode={navlinkMode.primary}
              key={item.label}
            />
          ))}
          {token && (
            <BaseNavlink
              onClick={() => to(DASHBOARD_ROUTE)}
              href={DASHBOARD_ROUTE}
              label="Dashboard"
              mode={navlinkMode.primary}
            />
          )}
          <ButtonGroup>
            {token && (
              <BaseButton
                type={buttonType.accent}
                label="Log out"
                onClick={handleLogoutClick}
              />
            )}
            {!token && (
              <Fragment>
                <BaseButton
                  type={buttonType.accent}
                  label="Log in"
                  onClick={handleLoginClick}
                />
                <BaseButton
                  type={buttonType.primary}
                  label="Sign Up"
                  onClick={handleSignClick}
                />
              </Fragment>
            )}
          </ButtonGroup>
        </MobileSidebarInner>
      </RootMobileSidebar>
    </RootNavigation>
  );
};

export default Navigation;
