import { Box } from '@mui/material';
import React from 'react';

import Navigation from './Navigation/Navigation';
import Footer from './Footer/Footer';

const LandingLayout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <Box
        pt="68px"
        sx={{
          '@media(max-width: 1024px)': {
            paddingTop: '65px',
          },
        }}
      >
        {children}
      </Box>
      <Footer />
    </div>
  );
};

export default LandingLayout;
