import { useRoutes } from 'react-router';
import React, { lazy } from 'react';

import { LandingLayout, DashboardLayout } from 'layout';
import * as Routes from './path';
import LockedPage from 'features/LockedPage/LockedPage';

const LazyHomepage = lazy(() => import('features/Homepage/Homepage'));
const LazyAboutUsPage = lazy(() => import('features/AboutUsPage/AboutUsPage'));
const LazyOurProductPage = lazy(() =>
  import('features/ProductPage/ProductPage'),
);
const LazyBadgesPage = lazy(() => import('features/BadgesPage/BadgesPage'));
const LazyBadgeDirectoryPage = lazy(() =>
  import('features/BadgeDirectoryPage/BadgeDirectoryPage'),
);

const LazyTermsAndConditionsPage = lazy(() =>
  import('features/TermsAndConditionsPage/TermsAndConditionsPage'),
);
const LazyPrivacyPolicyPage = lazy(() =>
  import('features/PrivacyPolicyPage/PrivacyPolicyPage'),
);
const LazyStandardsPage = lazy(() =>
  import('features/StandardsPage/StandardPage'),
);

const LazyLoginPage = lazy(() => import('features/LoginPage/LoginPage'));
const LazySignPage = lazy(() => import('features/SignPage/SignPage'));
const LazyRecoveryPasswordPage = lazy(() =>
  import('features/RecoveryPasswordPage/RecoveryPasswordPage'),
);
const LazyResetPasswordPage = lazy(() =>
  import('features/ResetPasswordPage/ResetPasswordPage'),
);

const LazyDashboardPage = lazy(() =>
  import('features/DashboardPage/DashboardPage'),
);
const LazySubscriptionPage = lazy(() =>
  import('features/DashboardSubscriptionPage/DashboardSubscriptionPage'),
);
const LazyDashboardSupportPage = lazy(() =>
  import('features/DashboardSupport/DashboardSupport'),
);
const LazyDashboardDirectoryPage = lazy(() =>
  import('features/DashboardDirectoryPage/DashboardDirectoryPage'),
);
const LazyPaymentPage = lazy(() => import('features/Payment/Payment'));
const LazyPaymentSuccessPage = lazy(() =>
  import('features/PaymentSuccessPage/PaymentSuccessPage'),
);
const LazyPaymentCancelPage = lazy(() =>
  import('features/PaymentCancelPage/PaymentCancelPage'),
);
const LazyChangePlanPage = lazy(() =>
  import('features/ChangePlanPage/ChangePlanPage'),
);

const routes = [
  {
    path: Routes.HOMEPAGE_ROUTE,
    element: (
      <LandingLayout>
        <LazyHomepage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.PAYMENT_ROUTE,
    element: (
      <LandingLayout>
        <LazyPaymentPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.PAYMENT_SUCCESS,
    element: <LazyPaymentSuccessPage />,
  },
  {
    path: Routes.PAYMENT_CANCEL,
    element: <LazyPaymentCancelPage />,
  },
  {
    path: Routes.CHANGE_PLAN,
    element: (
      <LandingLayout>
        <LazyChangePlanPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.ABOUT_US_ROUTE,
    element: (
      <LandingLayout>
        <LazyAboutUsPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.OUR_PRODUCT_ROUTE,
    element: (
      <LandingLayout>
        <LazyOurProductPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.TERMS_AND_CONDITIONS_ROUTE,
    element: (
      <LandingLayout>
        <LazyTermsAndConditionsPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.PRIVACY_POLICY_ROUTE,
    element: (
      <LandingLayout>
        <LazyPrivacyPolicyPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.STANDARDS_ROUTE,
    element: (
      <LandingLayout>
        <LazyStandardsPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.BADGES_ROUTE,
    element: (
      <LandingLayout>
        <LazyBadgesPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.LOGIN_ROUTE,
    element: (
      <LandingLayout>
        <LazyLoginPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.SIGN_ROUTE,
    element: (
      <LandingLayout>
        <LazySignPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.RECOVERY_PASSWORD_ROUTE,
    element: (
      <LandingLayout>
        <LazyRecoveryPasswordPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.RESET_PASSWORD_ROUTE,
    element: (
      <LandingLayout>
        <LazyResetPasswordPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.DASHBOARD_ROUTE,
    element: (
      <DashboardLayout>
        <LazyDashboardPage />
      </DashboardLayout>
    ),
  },
  {
    path: Routes.SUBSCRIPTION_ROUTE,
    element: (
      <DashboardLayout>
        <LazySubscriptionPage />
      </DashboardLayout>
    ),
  },
  {
    path: Routes.DASHBOARD_SUPPORT_ROUTE,
    element: (
      <DashboardLayout>
        <LazyDashboardSupportPage />
      </DashboardLayout>
    ),
  },
  {
    path: Routes.BADGE_DIRECTORY_ROUTE,
    element: (
      <LandingLayout>
        <LazyBadgeDirectoryPage />
      </LandingLayout>
    ),
  },
  {
    path: Routes.DASHBOARD_DIRECTORY_ROUTE,
    element: (
      <DashboardLayout>
        <LazyDashboardDirectoryPage />
      </DashboardLayout>
    ),
  },
  {
    path: Routes.LOCKED_ROUTE,
    element: <LockedPage />,
  },
];

const Router = () => {
  const pages = useRoutes(routes);
  if (!pages)
    return <LazyHomepage />;
  return pages;
};

export default Router;
