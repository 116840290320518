import { useState, useEffect } from 'react';

const useCookieConsent = () => {
  const [marketing, setMarketing] = useState(() => {
    const saved = localStorage.getItem('marketing');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [statistics, setStatistics] = useState(() => {
    const saved = localStorage.getItem('statistics');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const necessary = true;

  useEffect(() => {
    localStorage.setItem('marketing', JSON.stringify(marketing));
  }, [marketing]);

  useEffect(() => {
    localStorage.setItem('statistics', JSON.stringify(statistics));
  }, [statistics]);

  const acceptMarketing = () => setMarketing(true);
  const acceptStatistics = () => setStatistics(true);

  const getConsentState = () => {
    return {
      marketing,
      statistics,
      necessary,
    };
  };

  return {
    marketing,
    statistics,
    necessary,
    acceptMarketing,
    acceptStatistics,
    getConsentState,
  };
};

export default useCookieConsent;
