import { useLocation } from 'react-router';
import React from 'react';

import { BaseContainer, BaseNavlink, containerSize } from 'UI';
import { Logo } from 'layout';
import RootFooter, {
  FooterMenuList,
  RootCopyright,
  RootFooterMenu,
} from './Footer.styled';

import {
  PRIVACY_POLICY_ROUTE,
  STANDARDS_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from 'routes/path';

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <RootFooter>
      <BaseContainer size={containerSize.lg}>
        <RootFooterMenu>
          <Logo />
          <FooterMenuList>
            <BaseNavlink
              href={TERMS_AND_CONDITIONS_ROUTE}
              label="Terms and Conditions"
              active={TERMS_AND_CONDITIONS_ROUTE === pathname}
            />
            <BaseNavlink
              href={PRIVACY_POLICY_ROUTE}
              label="Privacy policy"
              active={PRIVACY_POLICY_ROUTE === pathname}
            />
            <BaseNavlink
              href={STANDARDS_ROUTE}
              label="Standards"
              active={STANDARDS_ROUTE === pathname}
            />
          </FooterMenuList>
        </RootFooterMenu>
        <RootCopyright>© All rights reserved</RootCopyright>
      </BaseContainer>
    </RootFooter>
  );
};

export default Footer;
